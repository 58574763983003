import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import TickIcon from 'assets/icons/tick-circle.svg';
import theme from 'utils/theme';

interface HorizontalListWithImgProps {
  imgUrl: string;
  botImgUrl?: string;
  list: { text: string; title?: string; icon?: string | null }[];
  title: string;
}

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: linear-gradient(180deg, #faf6f5 0.05%, #e4dfed 100%);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5rem;
  width: 100%;
  max-width: 1040px;
  padding: 6rem 2rem 4rem 2rem;

  @media ${tablet} {
    flex-direction: column;
    gap: 1.5rem;
    padding: 3rem 1rem;
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const Image = styled(DynamicImage)`
  flex: 1;
  min-height: 29.744375rem;
  width: 100%;
  height: 100%;
  max-width: 500px;
  @media ${tablet} {
    max-width: 400px;
    min-height: unset;
  }
`;

const ImageBot = styled(Image)`
  max-width: 343px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  // flex: inherit;
  max-width: 50%;
  gap: 1rem;
  @media ${tablet} {
    flex-direction: column;
    margin-left: 0;
    max-width: none;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
  @media ${tablet} {
    align-items: flex-start;
  }
`;

const TickIconStyled = styled(TickIcon)`
  height: 1.5rem;
  width: 1.5rem;
`;

const IconContainer = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.75rem;
`;

const ImageContainer = styled.div``;

const StyledDynamicImage = styled(DynamicImage)`
  object-fit: contain;
  width: 3rem;
  height: 3rem;
`;

const ListItemTitle = styled(Text)`
  @media ${mobile} {
    font-size: 1rem;
  }
`;
const ListItemLabel = styled(Text)`
  @media ${mobile} {
    font-size: 0.75rem;
  }
`;

const HorizontalListWithImg: FC<HorizontalListWithImgProps> = ({
  imgUrl,
  list,
  title,
  botImgUrl,
}) => {
  const { isTablet } = useQuery();

  const renderListItem = (
    item: { text: string; title?: string; icon?: string | null },
    index: number,
  ) => (
    <ListItemContainer key={`${title}-${index}`}>
      {item?.icon ? (
        <ImageContainer>
          <StyledDynamicImage src={item.icon} alt={item?.text || 'icon'} />
        </ImageContainer>
      ) : (
        <IconContainer>
          <TickIconStyled fill={theme.colors.secondary} />
        </IconContainer>
      )}
      <TextWrapper>
        {item?.title && (
          <ListItemTitle
            type="h4"
            textAlign="left"
            dangerouslySetInnerHTML={{ __html: item?.title }}
          />
        )}
        <ListItemLabel
          type="captionM"
          textAlign="left"
          dangerouslySetInnerHTML={{ __html: item.text }}
        />
      </TextWrapper>
    </ListItemContainer>
  );

  return (
    <OuterContainer>
      <Container>
        <Image src={imgUrl} alt="" />

        <ListContainer>
          <Text type="h2" textAlign={isTablet ? 'center' : 'left'}>
            {title}
          </Text>
          {list.map(renderListItem)}
        </ListContainer>
        {botImgUrl && isTablet ? <ImageBot src={botImgUrl} alt="" /> : null}
      </Container>
    </OuterContainer>
  );
};

export default HorizontalListWithImg;
